<template>
    <div
        class="phone-success-verification"
    >
        <div class="phone-success-verification__num">
            <span>Phone: </span>
            <strong> +({{ phoneCode }}){{ phone }}</strong>
        </div>

        <!-- <div
            @click="reset"
        >
            Change number
        </div> -->

        <div class="phone-success-verification__icon">
            <CheckIcon />
        </div>
    </div>
</template>

<script>
// icons
import CheckIcon from 'mdi-vue/Check'

export default {
    name: 'PhoneNumberValid',
    components: {
        CheckIcon
    },
    props: {
        phoneCode: {
            type: Number,
            required: true
        },
        phone: {
            type: String,
            required: true
        }
    },
    methods: {
        reset() {
            this.$store.commit('phone/RESET')
        }
    }
}
</script>

<style lang="scss" scoped>
    .phone-success-verification__num {
        span {
            display: block;
            margin-right: 4px;
        }
    }
</style>
