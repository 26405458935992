<template>
    <div class="phone__row">
        <vue-tel-input
            ref="vueTel"
            v-model="phoneInputValue"
            valid-characters-only
            :wrapper-classes="{error: ($v.phone_number.$dirty && $v.phone_number.$anyError) || ($v.country.$anyError && $v.country.$invalid)}"
            :data-error-message="errorMessage"
            mode="international"
            invalid-msg="error"
            :input-options="{
                showDialCode: false,
            }"
            @input="handleInput"
        />
        <div class="phone-verification__btn">
            <custom-button
                :loading="phone_validation_loading"
                default
                :disabled="$v.$invalid"
                class="btn-base btn-main"
                @on-btn-click="verificationStart"
            >
                Verify
            </custom-button>
        </div>
    </div>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { mapState } from 'vuex';
import Messages from '@/helpers/InterfaceMessages_en'
import { eventBus } from '@/helpers/event-bus/'

import Api from '@/helpers/api/index.js'

// validation
import { validationRules } from '@/validation/phone/Rules.js'
import { formMessages } from '@/validation/phone/Messages.js'
import formValidationMixin from '@/mixins/formValidationMixin.js'

// VUEX
import { mapPhoneFields } from '@/store/modules/phone'

export default {
    name: 'PhoneNumberField',
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            phoneInputValue: '',
            phoneNumberValue: '',
            phoneValidationStatus: false,
            phone_validation_loading: false
        }
    },
    computed: {
        ...mapState('phone', {
            started: 'started',
            verified: 'verified'
        }),
        ...mapPhoneFields([
            'form_data.country',
            'form_data.phone_number'
        ]),
        isFormValid() {
            return !this.$v.$invalid
        },
        errorMessage() {
            if (this.$v.country.$anyError && this.$v.country.$invalid) {
                return this.validationMessage(this.$v.country)
            }
            if (this.$v.phone_number.$dirty && this.$v.phone_number.$anyError) {
                return this.validationMessage(this.$v.phone_number)
            }
            return ''
        }
    },
    watch: {
        phoneNumberValue(newPhoneNumber) {
            this.phone_number = newPhoneNumber
        }
    },
    mounted() {
        if (this.$route.name === 'profile_phone') {
            this.country = {}
            this.phone_number = ''
            eventBus.$on('saveSettings', () => {
                if (this.$v.$invalid) {
                    this.validate()
                    return
                }
                eventBus.$emit('validate', true)
            })
            return
        }
        eventBus.$on('clearPhone', () => {
            this.$v.$reset()
        })
        this.phoneNumberValue = this.phone_number
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        async verificationStart() {
            if (this.$v.$invalid) {
                await this.isValid()
                const errorName = this.findNameOfFirstError(this.$v);
                eventBus.$emit('showSnackBar', `Please complete the form: ${errorName}`, 'error');
            } else {
                this.phone_validation_loading = true
                Api.post('/api/phone-verification/send-pin', {
                    phone: this.phone_number,
                    phone_code: this.country.value
                    // recaptcha: payload
                }).then(() => {
                    this.$store.commit('phone/START')
                    this.phone_validation_error = false
                    eventBus.$emit('showSnackBar', Messages.modal_sent_phone_verification_pin.body, 'success');
                }).catch((error) => {
                    eventBus.$emit('showSnackBar', error, 'error');
                    this.phone_validation_error = true
                    this.phone_validation_message = error
                    setTimeout(() => {
                        this.phone_validation_error = false
                        this.phone_validation_message = ''
                    }, 2000)
                }).finally(() => {
                    this.phone_validation_loading = false
                })
            }
        },
        handleInput() {
        // Предотвращаем добавление лишних символов "+"
            const { response } = this.$refs.vueTel;
            if (!this.phoneInputValue.startsWith('+')) {
                this.phoneInputValue = `+${this.phoneInputValue}`;
            }

            // Получаем код страны из response
            const countryDialCode = response.country.dialCode;

            // Проверяем, начинается ли введенный номер с кода страны
            if (this.phoneInputValue.startsWith(`+${countryDialCode}`)) {
                // Разделяем код страны и номер телефона
                const [, phoneNumber] = this.phoneInputValue.split(`+${countryDialCode}`);
                this.country = {
                    iso: response.country.iso2,
                    text: response.country.name,
                    value: countryDialCode
                };
                this.phoneNumberValue = phoneNumber.replace(/\s+/g, '');
            } else {
                // Если введенный номер не начинается с кода страны, считаем его невалидным
                this.country = {};
                this.phoneNumberValue = '';
            }
            this.$v.country.$touch()
            this.$v.phone_number.$touch()
        }
    }
}
</script>
